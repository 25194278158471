import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import MenuPage from "../components/MenuPage/MenuPage"
import Layout from "./../components/Layout/Layout"
export default function report() {
  return (
    <div>
      <Layout>
        <NavigationBar />
        <MenuPage />
      </Layout>
    </div>
  )
}
