import React from "react"
import "./MenuPage.scss"

export default function MenuPage() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-md-4 col-lg-4 d-flex align-items-sketch">
          <p>Is discrimination in the hiring process really a thing?</p>
        </div>
        <div className="col-lg-4 col-md-5">
          <div className="report">
            <p>EXPLORE</p>
          </div>
          <div className="card">
            <div className="card-body">
              <a href="#" className="button link-pink">
                <span>Our</span>
                <span>investigation</span>
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <a href="#" className="button link-yellow">
                <span>The</span> <span>CV</span> <span>Builder</span>
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <a href="#" className="button link-green">
                <span>The</span> <span>Anon CV</span> tool
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

;<div className="col">
  <div className="card text-center">
    <div className="card-body">
      <h5 className="card-title">Special title treatment</h5>
      <p className="card-text">
        With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="#" className="btn btn-primary">
        Go somewhere
      </a>
    </div>
  </div>

  <div className="card text-center">
    <div className="card-body">
      <h5 className="card-title">Special title treatment</h5>
      <p className="card-text">
        With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="#" className="btn btn-primary">
        Go somewhere
      </a>
    </div>
  </div>

  <div className="card text-right">
    <div className="card-body">
      <h5 className="card-title">Special title treatment</h5>
      <p className="card-text">
        With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="#" className="btn btn-primary">
        Go somewhere
      </a>
    </div>
  </div>
</div>
